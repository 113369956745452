import { graphql } from "gatsby";
import HelpIndex from "../../templates/helpIndex";

const TrustIndexPage = ({
	data: {
		craft: { entry },
	},
}) => <HelpIndex entry={entry} />;

export const pageQuery = graphql`
	query trustCenterIndexPage {
		craft {
			entry(slug: "trust-center-index") {
				... on CraftCMS_trustCenterIndex_trustCenterIndex_Entry {
					heroTitle
					heroText
					indexPageBlocks {
						... on CraftCMS_indexPageBlocks_indexPageBlock_BlockType {
							id
							indexPageBlockTitle
							indexPageBlockText
							indexPageBlockButton {
								element {
									slug
									uri
								}
								url
								type
								title
								text
								target
								customText
							}
						}
					}
					metaTitle
					metaShareImage {
						filename
						url
					}
					metaDescription
				}
			}
		}
	}
`;

export default TrustIndexPage;
